import React from "react";
import ReactDOM from "react-dom";
import bridge from "@vkontakte/vk-bridge";
import App from "./App";
import {
  AdaptivityProvider,
  ConfigProvider
} from "@vkontakte/vkui";

// Init VK  Mini App
bridge.send("VKWebAppInit");

ReactDOM.render(
  <ConfigProvider isWebView={true}>
    <AdaptivityProvider>
      <App />
    </AdaptivityProvider>
  </ConfigProvider>, document.getElementById('root'));

/*ReactDOM.render(<App />, document.getElementById("root"));
if (process.env.NODE_ENV === "development") {
  import("./eruda").then(({ default: eruda }) => {}); //runtime download
}*/
