import {default as bridge} from '@vkontakte/vk-bridge';

export function convertTextToLines(text, font, max_width) {
    const
        {createCanvas} = require('canvas'),
        canvas = createCanvas(1080, 1920),
        ctx = canvas.getContext('2d')
    ;
    ctx.font = font;
    let width = 0, lines = [], result, i, j;

    while (text.length) {
        for (i = text.length; ctx.measureText(text.substr(0, i)).width > max_width; i--) ;
        result = text.substr(0, i);

        if (i !== text.length)

            for (j = 0; result.indexOf(' ', j) !== -1; j = result.indexOf(' ', j) + 1) ;
        lines.push(result.substr(0, j || result.length));

        width = Math.max(width, ctx.measureText(lines[lines.length - 1]).width);
        text = text.substr(lines[lines.length - 1].length, text.length);
    }

    return lines;
}

export async function get(url, params) {
    const
        query = params ? '?' + Object.keys(params).map((value) =>
            encodeURIComponent(value) + '=' + encodeURIComponent(params[value])
        ).join('&') : '',
        url_ = `${url}${query}`;
    return await new Promise((res, rej) => {
        fetch(url_, {method: 'GET'})
            .then(res =>
                res.json()
            )
            .then(answer =>
                res(answer)
            ).catch(err =>
            res({error: {code: -1, text: err.toString()}})
        );
    });
}

export const defaultFonts = [
    'SF Pro Text',
    'SF Pro Text Heavy',
    'SF Pro Text Semibold',
    'SF Pro Display',
    'SF Pro Display Bold',
    'SF Pro Display Semibold',
    'SF Pro Display Medium',
    'SF Pro Rounded',
    'SF Pro Rounded Semibold',
    'SF Pro Rounded Bold',
    'SF UI Display',
    'SF UI Text',
    'TT Commons',
    'TT Commons Bold',
    'Manrope ExtraBold'
];

export function loadFonts(fonts = defaultFonts) {
    for (const font of fonts) {
        const span = document.createElement('span');
        span.style.fontFamily = font;
        span.innerText = '.';
        document.body.appendChild(span);
        setTimeout(() => span.remove(), 1);
    }
}

export function animateValue(obj, start, end, duration) {
    if (start === end || end - start === 1) {
        obj.innerHTML = end;
    } else {
        let startTimestamp = null;
        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            obj.innerHTML = Math.floor(progress * (end - start) + start);
            if (progress < 1) {
                window.requestAnimationFrame(step);
            }
        };
        window.requestAnimationFrame(step);
    }
}

export function cps(array) {
    if (array.length !== 0) {
        let
            first_date = array[0],
            cur_pos = 0,
            clicks_per_second = []
        ;
        array = array.map(value => value - first_date);

        for (const click of array) {
            cur_pos = click < 1000 ? 0 : parseInt((click + '').substring(0, (click + '').length - 3));
            clicks_per_second[cur_pos] = clicks_per_second[cur_pos] > 0 ? clicks_per_second[cur_pos] + 1 : 1;
        }

        clicks_per_second = clicks_per_second.filter(value => value > 0);

        const
            max_cps = Math.max(...clicks_per_second),
            min_cps = Math.min(...clicks_per_second),
            mid_cps = Math.ceil((max_cps + min_cps) / 2)
        ;
        return {max_cps, min_cps, mid_cps};
    } else {
        return {max_cps: 0, min_cps: 0, mid_cps: 0};
    }
}

function componentToHex(c) {
    const hex = c.toString(16);
    return hex.length == 1 ? '0' + hex : hex;
}

export function rgbToHex(r, g, b) {
    return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function decOfNum(number, titles, needNumber = true) {
    if (number !== undefined) {
        let decCache = [],
            decCases = [2, 0, 1, 1, 1, 2];
        if (!decCache[number]) decCache[number] = number % 100 > 4 && number % 100 < 20 ? 2 : decCases[Math.min(number % 10, 5)];
        return (needNumber ? number + ' ' : '') + titles[decCache[number]];
    }
}

export function numToStr(number) {
    if (number > 0 && number <= 10) {
        const words = [
            'первое', 'второе', 'третье', 'четвертое', 'пятое', 'шестое', 'седьмое', 'восьмое', 'девятое', 'десятое'
        ];
        return words[number - 1];
    } else {
        return number + '';
    }
}

export function shortIntegers(int) {
    try {
        return int.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
    } catch (e) {
        return 0;
    }
}

export function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function nodeToString(node) {
    let tmpNode = document.createElement("div");
    tmpNode.appendChild(node.cloneNode(true));
    let str = tmpNode.innerHTML;
    tmpNode = node = null;
    return str;
}

export function convertMiliseconds(miliseconds) {
    let hours, minutes, total_hours, total_minutes, total_seconds;

    total_seconds = parseInt(Math.floor(miliseconds / 1000));
    total_minutes = parseInt(Math.floor(total_seconds / 60));
    total_hours = parseInt(Math.floor(total_minutes / 60));

    minutes = parseInt(total_minutes % 60);
    hours = parseInt(total_hours % 24);

    return hours + 'ч. ' + minutes + 'мин.';
}

export function convertMsToNormalTime(miliseconds) {
    let hours, minutes, seconds, total_hours, total_minutes, total_seconds;

    total_seconds = parseInt(Math.floor(miliseconds / 1000));
    total_minutes = parseInt(Math.floor(total_seconds / 60));
    total_hours = parseInt(Math.floor(total_minutes / 60));

    seconds = parseInt(total_seconds % 60);
    minutes = parseInt(total_minutes % 60);
    hours = parseInt(total_hours % 24);

    return {hours, minutes, seconds};
}

const getImage = (src) =>
    new Promise((res, rej) => {
        const image = new Image();

        image.onload = () => res(image);
        image.crossOrigin = 'anonymous';
        image.onstalled = (e) => {
            console.log('Failed to fetch data, but trying.', e);
            rej(e);
        };
        image.onerror = (e) => {
            console.log('Failed to fetch data, error.', e);
            rej(e);
        };
        image.src = src;
    });

export async function toBlob(object, dataUrl = true) {
    const
        myCanvas = document.createElement('canvas'),
        ctxt = myCanvas.getContext('2d');

    let base = typeof object === 'string' ? object : window.btoa(object);
    const img = await getImage(typeof object === 'string' ? base : `data:image/svg+xml;base64,${base}`);
    myCanvas.height = img.height;
    myCanvas.width = img.width;
    if (ctxt === null) {
        return "";
    }
    ctxt.drawImage(img, 0, 0, img.width, img.height);
    return dataUrl ? myCanvas.toDataURL() : myCanvas;
}

export function getUrlParams() {
    return window.location.search.length > 0 && JSON.parse('{"' + decodeURI(window.location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
}

export const platforms = {
    desktop_web: /desktop_web/g,
    mobile_android: /mobile_android/g,
    mobile_android_messenger: /mobile_android_messenger/g,
    mobile_ipad: /mobile_ipad/g,
    mobile_iphone: /mobile_iphone/g,
    mobile_iphone_messenger: /mobile_iphone_messenger/g,
    mobile_web: /mobile_web/g,

    android: /mobile_android|mobile_android_messenger/g,
    ios: /mobile_ipad|mobile_iphone|mobile_iphone_messenger/g
};

export function isPlatformIOS() {
    return getUrlParams().vk_platform === 'mobile_iphone';
}

export function isPlatformAndroid() {
    return platforms.android.test(getUrlParams().vk_platform);
}

export function isPlatformDesktop() {
    return getUrlParams().vk_platform === 'desktop_web';
}

export let vk_local_users = {},
    name_cases = ['first_name', 'last_name', 'first_name_dat', 'first_name_nom', 'first_name_gen', 'first_name_acc', 'first_name_ins', 'first_name_abl', 'last_name_dat', 'last_name_nom', 'last_name_gen', 'last_name_acc', 'last_name_ins', 'last_name_abl'];

export async function getVKUsers(ids) {
    const
        user_ids = [
            ...new Set(
                ids
                    .filter(value => vk_local_users[value] === undefined)
                    .map(
                        value => typeof value === 'number' ? value : value.replace('@', '')
                            .replace('id', '')
                            .replace('vk.com/', '')
                            .replace('http://', '')
                            .replace('https://', '')
                    )
            )
        ],
        i = Math.floor(user_ids.length / 100)
    ;

    if (user_ids.length > 0) {
        let users = [];

        for (let j = 0; j < i + 1; j++) {
            users = users.concat(
                await vkApiRequest('users.get', {
                    user_ids: user_ids.slice(j * 100, j * 100 + 100).join(','),
                    fields: ['screen_name', 'photo_100', 'photo_200', ...name_cases].join(',')
                })
            );
        }

        for (const user of users) {
            vk_local_users[user.id] = user;
        }
    }

    return ids.map(value => vk_local_users[value] || vk_local_users[Object.keys(vk_local_users).find(key => vk_local_users[key].screen_name === value)]);
}

export async function vkApiRequest(method, params = {}) {
    return (await bridge.send('VKWebAppCallAPIMethod', {
        method,
        params: {
            ...params,
            v: '5.126',
            access_token: params.access_token || '47bf45ba47bf45ba47bf45ba1347c8a732447bf47bf45ba271d2be46ed7aad788987004'
        }
    })).response
}

export function viewportToPixels(value) {
    const
        parts = value.match(/([0-9\.]+)(vh|vw)/),
        q = Number(parts[1]),
        side = window[['innerHeight', 'innerWidth'][['vh', 'vw'].indexOf(parts[2])]]
    ;
    return side * (q / 100)
}

export function openUrl(url) {
    const element = document.createElement('a');
    element.href = url;
    element.target = '_blank';
    element.click();
    element.remove();
}

export function getUrlLocation() {
    return window.location.origin + window.location.pathname.replace('/index.html', '');
}

export function getSrcUrl(resource) {
    return getUrlLocation() + resource.substring(1);
}