import React from 'react';
import '../css/main.css';
import { Snackbar, Panel, PanelHeader, Header, Group, Cell, Div, Avatar } from '@vkontakte/vkui';
import { View } from '@vkontakte/vkui';
import {ModalRoot, ModalCard} from "@vkontakte/vkui";
import bridge from '@vkontakte/vk-bridge';
import Button from "./Button";
import {animateValue, decOfNum, get, getRandomInt, getUrlParams, loadFonts, openUrl, shortIntegers} from "./utils";

//import {createCanvas, loadImage} from 'canvas';

import {Icon16ErrorCircleFill} from "@vkontakte/icons";
import {ReactComponent as IconResult} from "../img/coolicon.svg";
import {ReactComponent as IconVkTime} from "../img/coolicon1.svg";
import parser from "fast-xml-parser";

// ID Приложения VK Mini Apps
let use_app_id = 51488279;
// ID Группы вк
var use_app_group_id = "lada155";


const proxyUrl = ['https://murmuring-bastion-20764.herokuapp.com/', 'https://vds2056823.my-ihor.ru:8088/'][getRandomInt(0, 1)];


class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            popout: null,
            history: ['p0'],
            activePanel: 'p0',

            currentMessageIndex: 0,
            messages: [
                'Считаем друзей...',
                'Получаем дату регистрации...',
                'Анализируем общую активность странички...',
                'Оцениваем фотки...',
                'Смотрим подписки...',
                'Секретная информация...',
                'Оценка почти готова...'
            ],

            data: {}
        };

        this.componentDidMount = this.componentDidMount.bind(this);
        this.back = () => {
            if (this.state.popout !== null) {
                this.setState({popout: null});
                window.history.pushState({pop: 'popout'}, 'Title');
                return;
            }
            let {history} = this.state;
            if (history.length === 1) {
                bridge.send('VKWebAppClose', {status: 'success'});
            } else if (history.length > 1) {
                history.pop();
                this.setState({activePanel: history[history.length - 1], history, snackbar: null});
            }
        };

        this.go = (panel) => {
            let {history} = this.state;
            if (history[history.length - 1] !== panel) {
                history.push(panel);
                window.history.pushState({activePanel: panel}, 'Title');
                this.setState({activePanel: panel, history, snackbar: null});
            }
        }

        
    }

    async componentDidMount() {
        loadFonts(['TT Firs Neue', 'TT Firs Neue Medium']);

        window.addEventListener('popstate', e => {
            e.preventDefault();
            this.back();
        });

        bridge.subscribe(async ({detail: {type, data}}) => {
            if (type !== undefined) console.log(type, data);
            if (type === 'VKWebAppUpdateConfig') {
                const schemeAttribute = document.createAttribute('scheme');
                schemeAttribute.value = 'bright_light';
                document.body.attributes.setNamedItem(schemeAttribute);
                if (bridge.supports('VKWebAppSetViewSettings')) {
                    bridge.send('VKWebAppSetViewSettings', {
                        status_bar_style: 'dark',
                        action_bar_color: '#FFFFFF'
                    });
                }
            } else if (type === 'VKWebAppViewRestore') {
                this.setState({popout: null});
            }
        });

        //this.setState({app: (await get(getAppUrl, {app_id: getUrlParams().vk_app_id})).response});

        bridge.send('VKWebAppInit');
        bridge.send('VKWebAppEnableSwipeBack');
    }
    
    async setToken() {
        const response = await bridge.send('VKWebAppGetAuthToken', {
            app_id: use_app_id,
            scope: 'friends,wall,photos,groups'
        });
        const user_id = await bridge.send('VKWebAppGetUserInfo');
        //console.log( user_id.id);
        this.setState({id_vk_user: user_id.id});
        if (response.scope.indexOf('wall') > -1 && response.scope.indexOf('friends') > -1 && response.scope.indexOf('photos') > -1 && response.scope.indexOf('groups') > -1) {
            this.setState({token: response.access_token});
        }
    }

    async shareStory() {
        try {
            const canvas = await this.getStoryCanvas();
            await bridge.send('VKWebAppShowStoryBox', {
                background_type: 'image',
                blob: canvas.toDataURL('image/png'),
                attachment: {
                    text: 'go_to',
                    type: 'url',
                    url: 'https://vk.com/app' + use_app_id
                }
            });

            if (this.state.token) {
                canvas.toBlob(async function (blob) {
                    this.uploadStoryPhotoToWall(blob);
                }.bind(this));
            }
        } catch (e) {

        }

        this.go('p7');
    }

    
    async getStoryCanvas() {
        const{createCanvas, loadImage} = require('canvas'),
            canvas = createCanvas(1080, 1920),
            ctx = canvas.getContext('2d'),
            {price} = this.state;

        const background = await loadImage('./img/Story.png');

        ctx.drawImage(background, 0, 0);
        ctx.textAlign = 'left';

        ctx.fillStyle = '#FFFFFF';
        ctx.font = '222px TT Firs Neue Medium';
        ctx.fillText(price, 167, 663 + 211);
        const {width} = ctx.measureText(price);

        ctx.fillStyle = '#2D4084';
        ctx.font = '96px TT Firs Neue Medium';
        ctx.fillText('руб', 167 + width + 26, 783 + 91);

        return canvas;
    }

    
    async uploadStoryPhotoToWall(blob) {
        const
            uploadWallUrl = (await bridge.send('VKWebAppCallAPIMethod', {
                method: 'photos.getWallUploadServer',
                params: {
                    v: '5.126',
                    access_token: this.state.token
                }
            })).response.upload_url,
            bodyFormData = new FormData()
        ;

        bodyFormData.append('photo', blob, 'image.png');

        try {
            fetch(proxyUrl + uploadWallUrl, {
                method: 'POST',
                body: bodyFormData
            })
                .then(res_ => {
                    return res_.json();
                })
                .then(async response => {
                    const {server, photo, hash} = response;
                    const wallPhoto = (await bridge.send('VKWebAppCallAPIMethod', {
                        method: 'photos.saveWallPhoto',
                        params: {
                            server,
                            photo,
                            hash,
                            caption: `💰 Узнай сколько стоит твоя страница в приложении - https://vk.com/app` + use_app_id,
                            v: '5.126',
                            access_token: this.state.token
                        }
                    })).response[0];

                    bridge.send('VKWebAppShowWallPostBox', {
                        message: '',
                        copyright: 'https://vk.com/app' + use_app_id,
                        attachments: `photo${wallPhoto.owner_id}_${wallPhoto.id}`
                    });
                });
        } catch (e) {
            console.error(e);
        }
    }

    async uploadStoryPhotoToAlbum(blob) {
        const
            album_id = (await bridge.send('VKWebAppCallAPIMethod', {
                method: 'photos.createAlbum',
                params: {
                    title: 'Сколько стоит моя страница',
                    v: '5.126',
                    access_token: this.state.token
                }
            })).response.id,
            uploadAlbumUrl = (await bridge.send('VKWebAppCallAPIMethod', {
                method: 'photos.getUploadServer',
                params: {
                    album_id,
                    v: '5.126',
                    access_token: this.state.token
                }
            })).response.upload_url,
            bodyFormData = new FormData()
        ;

        bodyFormData.append('photo', blob, 'image.png');

        try {
            fetch(proxyUrl + uploadAlbumUrl, {
                method: 'POST',
                body: bodyFormData
            })
                .then(res_ => {
                    return res_.json();
                })
                .then(async response => {
                    const {server, photos_list, hash} = response;
                    await bridge.send('VKWebAppCallAPIMethod', {
                        method: 'photos.save',
                        params: {
                            album_id,
                            server,
                            photos_list,
                            hash,
                            caption: '💰 Узнай сколько стоит твоя страница в приложении - https://vk.com/app' + use_app_id,
                            v: '5.126',
                            access_token: this.state.token
                        }
                    });
                });
        } catch (e) {
            console.error(e);
        }
    }


    render() {
        const
            {
                activePanel, activeModal, popout,
                messages, currentMessageIndex,
                snackbar,
                app
            } = this.state,

            panels = [
                {
                    title: 'Привет!',
                    description: 'Мы оцениваем твою страницу, считывая множество разных показателей. Оценка каждой страницы пропорциональна ее ценности.',
                    subdescription: 'Однако, наше приложение является развлекательным. Оно не пытается купить либо продать чью-либо страницу.',
                    button:
                        <Button
                            onClick={() => this.setState({activePanel: 'p1'})}
                        >
                            Ок
                        </Button>
                },
                {
                    title: 'Для оценки вашей страницы нам понадобится доступ к личным данным... ',
                    button:
                        <Button
                            onClick={async () => {
                                try {
                                    bridge.send("VKWebAppShowNativeAds", {ad_format:"preloader"});
                                    await this.setToken();
                                    this.setState({activePanel: 'p2'})
                                } catch (e) {
                                    if (snackbar) return;
                                    this.setState({
                                        snackbar: <Snackbar
                                            onClose={() => this.setState({snackbar: null})}
                                            before={<Icon16ErrorCircleFill width={20} height={20}/>}
                                        >
                                            Без доступа я не смогу проанализировать твою страницу :(
                                        </Snackbar>
                                    });
                                }
                            }}
                        >
                            Разрешить доступ
                        </Button>
                },
                {
                    title: 'Спасибо, доступ получен!',
                    button:
                        <Button
                            onClick={async () => {
                                try {
                                    /*
                                    bridge.send('VKWebAppAllowMessagesFromGroup', {
                                        group_id: use_app_group_id,
                                        key: use_app_group_key
                                    });*/
                                    await bridge.send('VKWebAppJoinGroup', {
                                        group_id: use_app_group_id
                                    });
                                    
                                } catch (e) {
                                    
                                }

                                this.setState({activePanel: 'p3', subShow: false});
                                let interval = setInterval(async () => {
                                    if (!this.state.subShow) {
                                        const i = this.state.currentMessageIndex;
                                        if (i >= messages.length - 1) {
                                            clearInterval(interval);
                                            this.go('p4');
                                            return;
                                        } else {
                                            this.setState({currentMessageIndex: i + 1});
                                        }
                                        if (i === 4) {
                                            //this.setState({subShow: true, activeModal: 'group'});
                                        }
                                    }
                                }, 1250);

                                const
                                    savedData = (await bridge.send('VKWebAppStorageGet', {keys: ['price']})).keys[0].value
                                ;

                                if (parseInt(savedData) > 0) {
                                    await this.setState({price: parseInt(savedData)});
                                } else {
                                    await fetch(proxyUrl + 'https://vk.com/foaf.php?id=' + this.state.id_vk_user)
                                        .then(response => response.text())
                                        .then(async str => {
                                            try {
                                                const
                                                    jsonObj = (parser.parse(str, {
                                                        attrNodeName: 'attr',
                                                        textNodeName: '#text',
                                                        ignoreAttributes: false
                                                    }))['rdf:RDF']['foaf:Person'],
                                                    days = Math.round(
                                                        (Date.now() - new Date(jsonObj['ya:created'].attr['@_dc:date']).getTime()) / 1000 / 60 / 60 / 24
                                                    ),
                                                    friends = (await bridge.send('VKWebAppCallAPIMethod', {
                                                        method: 'friends.get',
                                                        params: {
                                                            v: '5.126',
                                                            access_token: this.state.token
                                                        }
                                                    })).response.count,
                                                    photos = (await bridge.send('VKWebAppCallAPIMethod', {
                                                        method: 'photos.getAll',
                                                        params: {
                                                            count: 0,
                                                            v: '5.126',
                                                            access_token: this.state.token
                                                        }
                                                    })).response.count,
                                                    groups = (await bridge.send('VKWebAppCallAPIMethod', {
                                                        method: 'groups.get',
                                                        params: {
                                                            count: 1,
                                                            v: '5.126',
                                                            access_token: this.state.token
                                                        }
                                                    })).response.count,
                                                    counters = (await bridge.send('VKWebAppCallAPIMethod', {
                                                        method: 'users.get',
                                                        params: {
                                                            fields: 'counters',
                                                            v: '5.126',
                                                            access_token: this.state.token
                                                        }
                                                    })).response[0].counters,
                                                    wall = (await bridge.send('VKWebAppCallAPIMethod', {
                                                        method: 'wall.get',
                                                        params: {
                                                            count: 1,
                                                            v: '5.126',
                                                            access_token: this.state.token
                                                        }
                                                    })).response.count,
                                                    price = Math.round(friends * 2 + days / 2 + photos * 10 + groups + counters.audios + (counters.followers || 1) * 2 + wall * 5)
                                                ;
                                                await this.setState({price});
                                                await bridge.send('VKWebAppStorageSet', {
                                                    key: 'price',
                                                    value: price + ''
                                                });
                                                console.log({friends, days, photos, groups, counters, wall});
                                            } catch (e) {
                                                console.error(e);
                                                if (snackbar) return;
                                                this.setState({
                                                    snackbar: <Snackbar
                                                        onClose={() => this.setState({snackbar: null})}
                                                        before={<Icon16ErrorCircleFill width={20} height={20}/>}
                                                    >
                                                        Упс, произошла ошибка в формуле.
                                                    </Snackbar>
                                                });
                                            }
                                        });
                                }
                            }}
                        >
                            Оценить мою страницу
                        </Button>
                },
                {
                    icon: './img/analysis/' + currentMessageIndex + '.png',
                    title: 'Идёт оценка...',
                    description: messages[currentMessageIndex]
                },
                {
                    title: 'Анализ завершен!',
                    button:
                        <Button
                            onClick={() => {
                                this.shareStory();
                            }}
                        >
                            Опубликовать анализ в истории
                        </Button>
                },
            ],

            modal = (
                <ModalRoot
                    activeModal={activeModal}
                    onClose={() => this.setState({activeModal: null})}
                >
                    <ModalCard
                        id='group'
                        onClose={() => {
                            this.setState({subShow: false, activeModal: null});
                        }}
                        icon={<Avatar size={72} shadow={false}
                                      src='./img/group_subscribe.png'/>}
                        header='Пока ты ждешь анализ, подпишись на наш крутой паблик. Тебе понравится!'
                        actions={
                            <Button
                                style={{
                                    background: './img/btn_background.png',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat'
                                }}
                                onClick={async () => {
                                    try {
                                        await bridge.send('VKWebAppJoinGroup', {
                                            group_id: use_app_group_id
                                        });
                                    } catch (e) {
                                        console.error(e);
                                    }
                                    this.setState({subShow: false, activeModal: null});
                                }}>
                                Присоединиться
                            </Button>
                        }
                    />
                </ModalRoot>
            )
        ;

        return (
                <View
                    activePanel={activePanel}
                    popout={popout}
                    onSwipeBack={this.back}
                    //modal={modal}
                >
                    {
                        panels.map((value, index) =>
                            <Panel id={`p${index}`} key={`p${index}`}>
                                <div className='FullScreen__Container'>
                                <img alt='icon' className='FullScreen__Icon'
                                         src={value.icon ? value.icon : './img/' + index + '.png'}/>
                                    <div className='FullScreen__Title'>
                                        {value.title}
                                    </div>
                                    {
                                        value.subdescription ?
                                            <React.Fragment>
                                                <div style={{
                                                    marginTop: '2.44vh',
                                                    textAlign: 'left'
                                                }} className='FullScreen__Description'>
                                                    {value.description}
                                                </div>
                                                <div className='FullScreen__Subdescription'>
                                                    {value.subdescription}
                                                </div>
                                            </React.Fragment>
                                            :
                                            value.description && <div className='FullScreen__Description'>
                                                {value.description}
                                            </div>
                                    }
                                    {
                                        value.button &&
                                        React.cloneElement(value.button, {
                                            style: {
                                                background: './img/btn_background.png',
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat'
                                            }
                                        })
                                    }
                                </div>
                                {
                                    index === 3 && <React.Fragment>
                                        <div
                                            className='PercentTitle'>{Math.round(100 / messages.length * currentMessageIndex)}%
                                        </div>
                                        <img alt='wave' className='Loading'
                                             src='./img/loading.png'/>
                                    </React.Fragment>
                                }
                                <img alt='bg' className='Background' src='./img/bg.png'/>
                                {snackbar}
                            </Panel>
                        )
                    }
                    <Panel id='p7'>
                        <div className='FullScreen__Container'>
                            {
                                [
                                    {
                                        icon: <IconResult/>,
                                        text: 'Посмотреть результат',
                                        onClick: () => {
                                            this.go('p5');
                                        }
                                    }
                                ].map((value, index) =>
                                    <Button
                                        key={'btn-' + index}
                                        before={value.icon}
                                        onClick={() => value.onClick()}
                                        style={{
                                            marginTop: index > 0 && 22.5,
                                            background: './img/btn_background.png',
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat',
                                        }}
                                    >
                                        {value.text}
                                    </Button>
                                )
                            }
                        </div>
                        <img alt='bg' className='Background' src='./img/bg.png'/>
                    </Panel>
                    <Panel id='p5'>
                        <div className='TextHeader'>
                            Ваш результат:
                        </div>
                        <div
                            style={{
                                background: './img/result_bg.png',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: '3.47%, 12.31%'
                            }}
                            className='PriceContainer'>
                            <div>
                                Моя страница ВК стоит:
                            </div>
                            <div>
                                <span>{this.state.price}</span>
                                <span>руб</span>
                            </div>
                        </div>
                        <Button
                            style={{
                                background: `./img/btn_background.png`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                            }}
                            className='FullScreen__Button'
                            before={<IconResult/>}
                            onClick={() => this.shareStory()}
                        >
                            Поделиться в истории
                        </Button>
                        <img alt='bg' className='Background' src='./img/bg.png'/>
                    </Panel>
                    </View>
        );
    
	}
}
export default Home;
